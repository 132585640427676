var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      {
        directives: [{ name: "fullscreen", rawName: "v-fullscreen" }],
        attrs: {
          "custom-class": "base-form",
          visible: _vm.dialogVisible,
          width: _vm.width,
          top: _vm.top,
          "close-on-click-modal": false,
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "span",
        {
          staticClass: "base-title-font",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c("em", {
            staticClass: "iconfont icon-yuandianzhong",
            style: { color: _vm.$store.state.currentTheme.primaryColor },
          }),
          _vm._v(
            " " +
              _vm._s(
                _vm.isViewOnly
                  ? "View " + _vm.$t(_vm.title) + " Details"
                  : _vm.$t(_vm.formData.id ? "edit" : "add") +
                      " " +
                      _vm.$t(_vm.title) +
                      `${_vm.isCropStamp ? " - Stamp Upload" : ""}`
              ) +
              " "
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "body" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isCropStamp,
                  expression: "!isCropStamp",
                },
              ],
              ref: "form",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                inline: true,
                "label-position": "top",
              },
            },
            [
              _vm._l(_vm.fields, function (item) {
                return [
                  item.fieldType !== "invisible"
                    ? _c(
                        "el-form-item",
                        {
                          key: item.fieldName,
                          class: item.className && item.className,
                          attrs: {
                            label: _vm.$t(item.label || item.fieldName),
                            prop: item.fieldName,
                            rules: _vm.getRules(item),
                          },
                        },
                        [
                          !item.formConfig ||
                          ["input", "password"].includes(item.formConfig.type)
                            ? _c("el-input", {
                                attrs: {
                                  "show-password":
                                    item.formConfig &&
                                    item.formConfig.type === "password",
                                  "auto-complete": "new-password",
                                  disabled: _vm.isViewOnly || item.disabled,
                                },
                                on: {
                                  change: (e) =>
                                    item.changeEvent
                                      ? item.changeEvent(
                                          _vm.formData,
                                          item.fieldName,
                                          e
                                        )
                                      : () => {},
                                },
                                model: {
                                  value: _vm.formData[item.fieldName],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, item.fieldName, $$v)
                                  },
                                  expression: "formData[item.fieldName]",
                                },
                              })
                            : item.formConfig.type === "phone"
                            ? _c("phone-input", {
                                attrs: {
                                  disabled: _vm.isViewOnly || item.disabled,
                                },
                                model: {
                                  value: _vm.formData[item.fieldName],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, item.fieldName, $$v)
                                  },
                                  expression: "formData[item.fieldName]",
                                },
                              })
                            : item.formConfig.type === "inputNumber"
                            ? _c("el-input", {
                                attrs: {
                                  min: item.formConfig.min || -Infinity,
                                  max: item.formConfig.max || Infinity,
                                  disabled: _vm.isViewOnly || item.disabled,
                                },
                                model: {
                                  value: _vm.formData[item.fieldName],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      item.fieldName,
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "formData[item.fieldName]",
                                },
                              })
                            : item.formConfig.type === "radio"
                            ? _c(
                                "el-radio-group",
                                {
                                  attrs: {
                                    disabled: _vm.isViewOnly || item.disabled,
                                  },
                                  model: {
                                    value: _vm.formData[item.fieldName],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        item.fieldName,
                                        $$v
                                      )
                                    },
                                    expression: "formData[item.fieldName]",
                                  },
                                },
                                _vm._l(
                                  item.formConfig.options,
                                  function (option, index) {
                                    return _c(
                                      "el-radio",
                                      {
                                        key: index,
                                        attrs: { label: option.value },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t(option.text)) +
                                            " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : item.formConfig.type === "checkbox"
                            ? _c(
                                "el-checkbox-group",
                                {
                                  attrs: {
                                    disabled: _vm.isViewOnly || item.disabled,
                                  },
                                  model: {
                                    value: _vm.formData[item.fieldName],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        item.fieldName,
                                        $$v
                                      )
                                    },
                                    expression: "formData[item.fieldName]",
                                  },
                                },
                                _vm._l(
                                  item.formConfig.options,
                                  function (option, index) {
                                    return _c(
                                      "el-checkbox",
                                      {
                                        key: index,
                                        attrs: { label: option.value },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t(option.text)) +
                                            " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : item.formConfig.type === "select"
                            ? _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    disabled: _vm.isViewOnly || item.disabled,
                                  },
                                  model: {
                                    value: _vm.formData[item.fieldName],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        item.fieldName,
                                        $$v
                                      )
                                    },
                                    expression: "formData[item.fieldName]",
                                  },
                                },
                                _vm._l(
                                  item.formConfig.options,
                                  function (option) {
                                    return _c("el-option", {
                                      key: option.value,
                                      attrs: {
                                        label: option.label || option.value,
                                        value: option.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              )
                            : item.formConfig.type === "cascader"
                            ? _c("el-cascader", {
                                attrs: {
                                  options: item.formConfig.options,
                                  "show-all-levels":
                                    item.formConfig.showAllLevels === undefined
                                      ? true
                                      : item.formConfig.showAllLevels,
                                  props: item.formConfig.props,
                                  disabled: _vm.isViewOnly || item.disabled,
                                },
                                model: {
                                  value: _vm.formData[item.fieldName],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, item.fieldName, $$v)
                                  },
                                  expression: "formData[item.fieldName]",
                                },
                              })
                            : item.formConfig.type === "all-levels-cascader"
                            ? _c("el-cascader", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  clearable: "",
                                  "popper-class": "el-cascader-long",
                                  options: item.formConfig.options,
                                  "show-all-levels": true,
                                  props: item.formConfig.props,
                                  disabled: _vm.isViewOnly || item.disabled,
                                },
                                on: {
                                  change: function ($event) {
                                    item.changeEvent
                                      ? item.changeEvent(
                                          $event,
                                          _vm.formData,
                                          _vm.fields
                                        )
                                      : () => {}
                                  },
                                },
                                model: {
                                  value: _vm.formData[item.fieldName],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, item.fieldName, $$v)
                                  },
                                  expression: "formData[item.fieldName]",
                                },
                              })
                            : item.formConfig.type === "switch"
                            ? _c("el-switch", {
                                attrs: {
                                  "active-text":
                                    item.formConfig.activeText || "",
                                  "inactive-text":
                                    item.formConfig.inactiveText || "",
                                  disabled: _vm.isViewOnly || item.disabled,
                                },
                                on: {
                                  change: function ($event) {
                                    item.changeEvent
                                      ? item.changeEvent($event)
                                      : () => {}
                                  },
                                },
                                model: {
                                  value: _vm.formData[item.fieldName],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, item.fieldName, $$v)
                                  },
                                  expression: "formData[item.fieldName]",
                                },
                              })
                            : ["date", "datetime"].includes(
                                item.formConfig.type
                              )
                            ? _c("el-date-picker", {
                                attrs: {
                                  type: item.formConfig.type,
                                  "value-format":
                                    item.formConfig.type === "date"
                                      ? "yyyy-MM-dd"
                                      : "yyyy-MM-dd HH:mm:ss",
                                  disabled: _vm.isViewOnly || item.disabled,
                                },
                                model: {
                                  value: _vm.formData[item.fieldName],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, item.fieldName, $$v)
                                  },
                                  expression: "formData[item.fieldName]",
                                },
                              })
                            : item.formConfig.type === "time"
                            ? _c("el-time-picker", {
                                attrs: {
                                  "value-format": "HH:mm:ss",
                                  disabled: _vm.isViewOnly || item.disabled,
                                },
                                model: {
                                  value: _vm.formData[item.fieldName],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, item.fieldName, $$v)
                                  },
                                  expression: "formData[item.fieldName]",
                                },
                              })
                            : item.formConfig.type === "icon"
                            ? _c("icon-picker", {
                                attrs: {
                                  disabled: _vm.isViewOnly || item.disabled,
                                },
                                model: {
                                  value: _vm.formData[item.fieldName],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, item.fieldName, $$v)
                                  },
                                  expression: "formData[item.fieldName]",
                                },
                              })
                            : item.formConfig.type === "stamp-upload"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.isViewOnly || item.disabled,
                                        action: _vm.fileUploadUrl,
                                        accept: ".jpg,.png",
                                        "list-type": "picture",
                                        "file-list": item.formConfig.fileList
                                          ? item.formConfig.fileList
                                          : [],
                                        "on-change": _vm.onUploadStamp,
                                        "auto-upload": false,
                                      },
                                    },
                                    [
                                      _vm.isViewOnly || !item.disabled
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "min-width": "200px",
                                              },
                                              attrs: {
                                                size: "mini",
                                                type: "primary",
                                                plain: "",
                                                round: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(item.formConfig.desc)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "el-upload__tip",
                                          staticStyle: { margin: "0" },
                                          attrs: { slot: "tip" },
                                          slot: "tip",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.formConfig.message) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "div-stamp-preview" },
                                    [
                                      item.formConfig.fileList &&
                                      item.formConfig.fileList[0]
                                        ? _c("img", {
                                            attrs: {
                                              src: item.formConfig.fileList[0]
                                                .url,
                                            },
                                          })
                                        : _c("div", [
                                            _vm._v("No stamp uploaded"),
                                          ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isCropStamp,
                  expression: "isCropStamp",
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isStampLoading,
                  expression: "isStampLoading",
                },
              ],
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "min-height": "100px",
                    display: "flex",
                    "justify-content": "center",
                  },
                },
                [
                  _c("cropper", {
                    ref: "cropper",
                    staticClass: "cropper",
                    attrs: { src: (_vm.stampFile && _vm.stampFile.url) || "" },
                    on: {
                      change: _vm.handleStampCropChange,
                      ready: _vm.handleCropperReady,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      !_vm.isCropStamp
        ? _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", round: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isViewOnly ? _vm.$t("close") : _vm.$t("cancel")
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: _vm.isViewOnly,
                    size: "small",
                    round: "",
                    type: "primary",
                    loading: _vm.loading,
                  },
                  on: { click: _vm.submit },
                },
                [_vm._v(" " + _vm._s(_vm.$t("submit")) + " ")]
              ),
            ],
            1
          )
        : !_vm.isStampLoading
        ? _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", round: "" },
                  on: {
                    click: function ($event) {
                      _vm.isCropStamp = false
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isViewOnly ? _vm.$t("close") : _vm.$t("cancel")
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: _vm.isViewOnly,
                    size: "small",
                    round: "",
                    type: "primary",
                    loading: _vm.isStampBtnLoading,
                  },
                  on: { click: _vm.handleUploadStampOnClick },
                },
                [_vm._v(" " + _vm._s(_vm.$t("upload")) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }